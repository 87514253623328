<template>
  <div>
    <ConnectFacebook
      v-if="contentKind === KindType.facebookPost"
      :social-media-pages="socialMediaPages"
      @connectToFacebook="connectToSocialMedia(KindName.facebookPost)"
      @openConnectSocialMediaPage="openConnectSocialMediaPage"
      @close="closeDialog"
    />
    <ConnectInstagram
      v-if="contentKind === KindType.instagramPost"
      :has-instagram-connection="hasInstagramConnection"
      @openConnectSocialMediaPage="openConnectSocialMediaPage"
      @connectToInstagram="connectToSocialMedia(KindName.instagramPost)"
      @close="closeDialog"
    />
  </div>
</template>
<script>
import KindType, { KindName, KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import brandingMixin from '@/mixins/branding'
import ConnectInstagram from '@/modules/contentCreator/creator/SocialMedia/ConnectInstagram.vue'
import ConnectFacebook from '@/modules/contentCreator/creator/SocialMedia/ConnectFacebook.vue'

export default {
  components: { ConnectFacebook, ConnectInstagram },
  mixins: [brandingMixin],
  props: {
    socialMediaPages: {
      type: Object,
      default: () => {}
    },
    contentKind: {
      type: String,
      default: () => ''
    },
    hasInstagramConnection: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    KindName () {
      return KindName
    },
    KindType () {
      return KindType
    },
    KindTypeIcon () {
      return KindTypeIcon
    }
  },
  methods: {
    openConnectSocialMediaPage () {
      this.$emit('openConnectSocialMediaPage')
    },
    connectToSocialMedia (kind) {
      this.$emit('connectToSocialMedia', kind)
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}

</script>
