<template>
  <div class=" full-size-container h-full">
    <v-card
      outlined
      class="px-2 pt-2 px-md-4 "
    >
      <div class="d-flex justify-space-between">
        <v-tabs
          v-model="section"
          show-arrows
        >
          <v-tab
            replace
            to="#creator"
          >
            {{ $t('content-creator.tabs.creator') }}
          </v-tab>
          <v-tab
            replace
            to="#all"
          >
            {{ $t('content-creator.tabs.saved-content') }}
            <v-badge
              v-if="savedContentCounter > 0"
              color="red"
              :content="savedContentCounter"
              offset-x="0"
            >
              <v-icon
                icon="$vuetify"
                size="x-large"
              />
            </v-badge>
          </v-tab>
        </v-tabs>
      </div>
      <v-divider />
      <v-tabs-items
        v-model="section"
        class="pt-4"
        touchless
      >
        <v-tab-item value="creator">
          <Creator
            v-if="section === 'creator'"
            @savedContent="incrementSavedContentCounter"
          />
        </v-tab-item>
        <v-tab-item value="all">
          <SavedContent
            :update-saved-contents="savedContentCounter"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import Creator from './creator'
import SavedContent from './creator/SavedContent.vue'
import { FilterType } from './creator/enums/FilterType'
import KindType from './creator/enums/KindType'
import feature from '@/mixins/feature'
import trackingEvents from '@/lib/trackingEvents'

export default {
  components: {
    SavedContent,
    Creator
  },
  mixins: [feature],
  data () {
    return {
      kind: KindType.facebookPost,
      section: '',
      savedContentCounter: 0
    }
  },
  watch: {
    section () {
      if (this.section === FilterType.ALL) {
        this.resetSavedContentCounter()
      }
    }
  },
  async mounted () {
    this.$gtm.trackEvent({ event: trackingEvents.CONTENT_CREATOR_OPENED })
  },
  methods: {
    incrementSavedContentCounter () {
      this.savedContentCounter++
    },
    resetSavedContentCounter () {
      this.savedContentCounter = 0
    }
  }
}
</script>
