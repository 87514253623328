<template>
  <v-card class="elevation-0 pb-3">
    <EditContent
      v-if="showEditContentDialog"
      :content="selectedContent"
      @update="updateText"
      @close="closeEditDialog"
    />
    <Connect
      v-if="showSocialMediaDialog"
      :social-media-connections="socialMediaConnections"
      :content="{
        text: selectedContent.text,
        image: selectedContent.image?.url,
        imageSource,
        textKind: kind,
        landingPageId: topic?.landingpage?.id,
        topicId: topic?.id,
        topicTemplateId: topic?.textAiTopicTemplate?.id,
        slug: topic?.name
      }"
      :post-from-history-page="false"
      @close="closeConnectDialog"
      @refreshSocialMediaConnections="refreshSocialMediaConnections"
      @savedContent="$emit('savedContent')"
    />
    <v-row class="d-flex">
      <v-col class="col-sm-8 py-0 py-sm-4">
        <div class="d-md-flex justify-md-center align-md-center">
          <v-autocomplete
            v-model="kind"
            :items="availableKinds"
            outlined
            hide-details
            :disabled="isGenerating"
            dense
            chips
            class="mr-sm-2 my-2 pointer"
          >
            <template v-slot:selection="data">
              <v-chip
                :color="$vuetify.theme.themes.light.primary"
                outlined
              >
                <v-avatar
                  left
                  size="20"
                >
                  <v-img :src="data.item.icon" />
                </v-avatar>
                <span class="ml-3">{{ data.item.text }}</span>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-avatar
                left
                size="20"
              >
                <v-img :src="data.item.icon" />
              </v-avatar>
              <span class="ml-3">{{ data.item.text }}</span>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="topicId"
            :items="topicSelectItems"
            outlined
            hide-details
            :disabled="isGenerating"
            dense
            class="mr-sm-2 my-2 pointer"
          >
            <template v-slot:selection="data">
              <v-chip
                :color="$vuetify.theme.themes.light.primary"
                outlined
              >
                {{ data.item.text }}
              </v-chip>
            </template>
          </v-autocomplete>
          <v-btn
            class="w-xs-full mx-sm-2 my-sm-2 generate-btn"
            color="primary"
            elevation="0"
            :disabled="isGenerating || !kind || !topic"
            @click="generate"
          >
            <v-icon
              left
              dark
            >
              mdi-refresh
            </v-icon>
            {{ $t('buttons.content-creator.generate-content') }}
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-if="imageSources.length > 1"
        sm="2"
        cols="12"
        offset-sm="2"
        class="d-flex justify-end pt-5"
      >
        <v-select
          v-model="imageSource"
          outlined
          dense
          hide-details
          :label="$t('buttons.content-creator.image-source')"
          validate-on-blur
          :items="imageSourceItems"
        />
      </v-col>
    </v-row>
    <v-row>
      <info-box
        v-if="!isGenerating && isGeneratedContentObjEmpty"
        :info-message="infoMessages.generateContent"
      />
      <v-col>
        <div
          v-if="isGenerating"
          class="d-flex justify-center align-center"
          :style="{ height: spinnerHeight + 'px' }"
        >
          <ProgressCircular />
        </div>
        <div
          v-if="!isGenerating && !isGeneratedContentObjEmpty && generatedContent?.text?.choices"
          ref="creator"
        >
          <GeneratedContent
            :kind="kind"
            :has-image="hasImage"
            :generated-content="generatedContent"
            @openEditTextDialog="openEditTextDialog"
            @change="handleChange"
          />
        </div>
        <div class="w-full d-md-flex justify-md-center align-md-center mt-2 mt-sm-5">
          <DownloadPost
            class="w-xs-full my-2"
            :save-content="true"
            :kind="kind"
            :image="selectedContent?.image?.url"
            :image-source="imageSource"
            :text="selectedContent?.text"
            :topic="topic?.name"
            :topic-id="topic?.id"
            :url="topic?.landingpage?.url"
            :topic-template-id="topic?.textAiTopicTemplate?.id"
            :disabled="disabled"
            :primary="!hasSocialMediaConnection"
            @savedContent="$emit('savedContent')"
          />
          <v-tooltip
            top
            :disabled="mayPostSelectedKind"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  v-if="kind === KindType.facebookPost ||
                    kind === KindType.instagramPost"
                  class="mx-sm-3 w-xs-full social-media-btn"
                  :text="!hasSocialMediaConnection"
                  :outlined="!hasSocialMediaConnection"
                  :disabled="disabled || !mayPostSelectedKind"
                  :color="hasSocialMediaConnection ? 'primary' : ''"
                  elevation="0"
                  @click="openSocialMediaDialog"
                >
                  <span v-if="!hasSocialMediaConnection">
                    <v-icon
                      size="14"
                      class="mr-2"
                    >
                      mdi-transit-connection-horizontal
                    </v-icon>
                    {{ $t('buttons.content-creator.connect-to-social-media') }}
                  </span>
                  <span v-if="hasSocialMediaConnection">
                    {{ $t('buttons.content-creator.share') }}
                  </span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t('buttons.content-creator.comming-soon') }}</span>
          </v-tooltip>
        </div>
        <GenerationLimits />
        <!--        <info-box
          class="alert"
          :info-message="infoMessages.publishPostExample"
        />-->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import lightMaterials from '@/lib/lightMaterials'
import KindType, { KindTypeIcon, ReadableKindType } from './enums/KindType'
import GET_TOPICS from './queries/getTopics.gql'
import GENERATE_TOPIC_TEXT from './queries/generateTopicText.gql'
import GET_LATEST_GENERATED_TEXT from './queries/getLatestGeneratedText.gql'
import GET_MATERIALS from './queries/getMaterials.gql'
import GET_STOCK_PHOTOS from './queries/getStockPhotos.gql'
import GET_GENERATED_IMAGES from './queries/getGeneratedImages.gql'
import GET_USER_IMAGES from './queries/getUserImages.gql'
import UPLOAD_USER_IMAGE from './queries/uploadUserImage.gql'
import GENERATE_IMAGE from './queries/generateImage.gql'
import GeneratedContent from './GeneratedContent.vue'
import { partMapper } from './lib/partMapper'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import InfoBox from '@/components/InfoBox.vue'
import ProgressCircular from '@/components/ProgressCircular.vue'
import EditContent from '@/modules/contentCreator/creator/EditContent.vue'
import Connect from '@/modules/contentCreator/creator/SocialMedia/Connect.vue'
import GET_SOCIAL_MEDIA_CONNECTIONS from '@/modules/contentCreator/creator/queries/getSocialMediaConnections.gql'
import trackingEvents from '@/lib/trackingEvents'
import setQueryParameters from '@/lib/setQueryParameters'
import DownloadPost from './DownloadPost.vue'
import { buildUrlWithTracking } from '@/modules/contentCreator/creator/lib/urlWithTracking'
import GenerationLimits from './GenerationLimits.vue'

export default {
  components: {
    Connect,
    EditContent,
    ProgressCircular,
    GeneratedContent,
    InfoBox,
    DownloadPost,
    GenerationLimits
  },
  mixins: [featureMixin, brandingMixin],
  props: { },
  data () {
    return {
      spinnerHeight: 300,
      isGenerating: false,
      KindType,
      imageSource: null,
      imageSources: [],
      stockPhotos: {
        page: 1,
        perPage: 20
      },
      enabledTextKinds: [],
      kind: KindType.facebookPost,
      topics: [],
      topicId: '',
      generatedContent: {
        image: {},
        text: {}
      },
      latestGeneratedText: {},
      selectedContent: {
        image: {},
        text: ''
      },
      showDialog: false,
      showSocialMediaDialog: false,
      showEditContentDialog: false,
      editTextDialogIndex: null,
      editTextTimeout: null,
      socialMediaConnections: null,
      enabledPostingChannels: {}
    }
  },
  computed: {
    topicsWithTemplate () {
      return this.topics
        .map(({ textAiTopicTemplates, ...topic }) => ({
          ...topic,
          textAiTopicTemplate: textAiTopicTemplates.find(({ textKind }) => textKind === this.kind)
        }))
        .filter(({ textAiTopicTemplate }) => textAiTopicTemplate)
    },
    topicSelectItems () {
      const categories = this.topicsWithTemplate
        .map((topic) => {
          return {
            value: topic.id,
            text: topic.name,
            type: topic.landingpage ? topic.landingpage.type : 'TOPIC'
          }
        })
        .reduce((acc, { type, ...rest }) => {
          if (type in acc) {
            acc[type].items.push(rest)
          } else {
            acc.TOOL.items.push({ type, ...rest })
          }
          return acc
        }, {
          TOPIC: { items: [], header: 'Topics' },
          TOOL: { items: [], header: 'Tools' },
          GUIDE: { items: [], header: 'Ratgeber' },
          CHECKLIST: { items: [], header: 'Checklisten' }
        })

      const slugs = []
      Object.values(categories).forEach(({ items, header }, index) => {
        if (items.length > 0) {
          if (index !== 0) {
            slugs.push({ divider: true })
          }
          slugs.push({ header }, ...items)
        }
      })
      return slugs
    },
    imageSourceItems () {
      return this.imageSources.map(source => ({
        text: this.$t('content-creator.image-sources.' + source),
        value: source
      }))
    },
    topic () {
      return this.topicsWithTemplate.find(({ id }) => id === this.topicId) || {}
    },
    hasSocialMediaConnection () {
      if (!this.socialMediaConnections) {
        return false
      }

      const isFacebookPost = this.kind === KindType.facebookPost
      const isInstagramPost = this.kind === KindType.instagramPost

      return (isFacebookPost && this.socialMediaConnections.facebook) ||
        (isInstagramPost && this.socialMediaConnections.instagram)
    },
    infoMessages () {
      return {
        generateContent: this.$t('alerts.content-creator.generate-content.info'),
        publishPostExample: this.$t('alerts.content-creator.social-media.info')
      }
    },
    availableKinds () {
      return Object.keys(KindType)
        .map(key => ({
          value: key,
          text: ReadableKindType[key],
          icon: KindTypeIcon[key]
        }))
        .map((kind) => ({
          ...kind,
          disabled: !(this.enabledTextKinds === 'all' || this.enabledTextKinds.includes(kind.value))
        }))
    },
    isGeneratedContentObjEmpty () {
      return this.generatedContent &&
          Object.keys(this.generatedContent).length === 0 &&
          this.generatedContent.constructor === Object
    },
    hasImage () {
      return this.generatedContent?.image?.choices?.length > 0
    },
    disabled () {
      return this.isGenerating || (partMapper[this.kind].includes('text') && !this.selectedContent?.text) ||
        (partMapper[this.kind].includes('image') && !this.selectedContent?.image?.url)
    },
    mayPostSelectedKind () {
      return this.enabledPostingChannels[this.kind]
    }
  },
  watch: {
    async imageSource () {
      this.setIsGenerating()
      await this.getImages()
      this.finishIsGenerating()
    },
    async kind () {
      this.$emit('kindChanged', this.kind)
      this.setIsGenerating()
      await this.getImages()
      this.setDefaultSelectedContent()
      this.finishIsGenerating()
    },
    topicSelectItems (topicSelectItems) {
      if (!this.topic?.id || !topicSelectItems.find(({ value }) => value === this.topic?.id)) {
        this.topicId = topicSelectItems.find(({ type }) => type === 'VALUATION')?.value || topicSelectItems.find(({ value }) => value)?.value
      }
    },
    async topicId (topicId) {
      if (topicId) {
        this.setIsGenerating()
        await this.getImages()
        this.finishIsGenerating()
      }
    }
  },
  async created () {
    const textAifeature = await this.getFeature(this.featureNames.TEXT_AI_TOPICS)
    const feature = await this.getFeature(this.featureNames.CONTENT_CREATOR)
    const config = feature.config

    this.enabledTextKinds = textAifeature.config.enabledTextKinds
    this.imageSources = config.imageSources
    this.imageSource = config.imageSources[0]
    this.stockPhotos.limit = config.stockImageLimit
    this.enabledPostingChannels = {
      [KindType.facebookPost]: config.postToFacebook,
      [KindType.instagramPost]: config.postToInstagram
    }
  },
  unmounted () {
    if (this.editTextTimeout) {
      clearTimeout(this.editTextTimeout)
    }
  },
  methods: {
    refreshSocialMediaConnections () {
      this.$apollo.queries.socialMediaConnections.refetch()
    },
    setIsGenerating (timeout = 3000) {
      if (this.isGeneratingTimeout) {
        clearTimeout(this.isGeneratingTimeout)
      }
      this.spinnerHeight = this.$refs?.creator?.clientHeight
      this.isGenerating = true
      this.isGeneratingTimeout = setTimeout(() => { this.isGenerating = false }, timeout)
    },
    finishIsGenerating () {
      if (this.isGeneratingTimeout) {
        clearTimeout(this.isGeneratingTimeout)
      }
      this.isGenerating = false
    },
    closeConnectDialog () {
      this.showSocialMediaDialog = false
    },
    closeEditDialog () {
      this.editTextDialogIndex = null
      this.showEditContentDialog = false
    },
    updateText (text) {
      this.spinnerHeight = this.$refs?.creator?.clientHeight
      this.isGenerating = true
      const filteredGeneratedContent = this.generatedContent.text.choices.filter((value, index) => index !== this.editTextDialogIndex)
      this.generatedContent.text.choices = [text, ...filteredGeneratedContent]
      this.selectedContent.text = text
      this.editTextTimeout = setTimeout(() => {
        this.isGenerating = false
      }, 500)
    },
    openEditTextDialog (index) {
      this.showEditContentDialog = true
      this.editTextDialogIndex = index
    },
    handleChange (data) {
      this.selectedContent[data.part] = data.content
    },
    setDefaultSelectedContent () {
      this.selectedContent.text = this.generatedContent?.text?.choices?.[0]
      this.selectedContent.image = this.generatedContent?.image?.choices?.[0]
    },
    randomlyShuffleElements (array) {
      // In order to not show always the same order of the array elements
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
      }
      return array
    },
    addLinkToText (text) {
      if (this.kind === KindType.facebookPost && this.topic?.landingpage?.url) {
        const url = this.topic?.landingpage?.url
        const hashtagIndex = text.indexOf('#')
        if (hashtagIndex === -1) {
          return text + ' \n\n' + buildUrlWithTracking(url, this.kind, this.readablePlatformName)
        }

        return text.slice(0, hashtagIndex) + ' \n' + buildUrlWithTracking(url, this.kind, this.readablePlatformName) + ' \n' + text.slice(hashtagIndex)
      }

      return text
    },
    async generate () {
      this.setIsGenerating(10000)
      this.generatedContent.text = { choices: await this.generateText() }
      this.setDefaultSelectedContent()
      this.isGenerating = false
      this.$gtm.trackEvent({
        event: trackingEvents.CONTENT_CREATOR_GENERATED,
        value: {
          type: ReadableKindType[this.kind],
          topic: this.topic.name,
          imageSource: this.imageSource
        }
      })
    },
    async generateText () {
      if (!partMapper[this.kind].includes('text')) return

      try {
        const { data } = await this.$apollo.mutate({
          mutation: GENERATE_TOPIC_TEXT,
          variables: {
            input: {
              topicTemplateId: this.topic?.textAiTopicTemplate?.id
            }
          },
          refetchQueries: ['getGeneratedTextsThisMonth']
        })

        if (data?.generatedText?.choices?.length > 0) {
          return this.randomlyShuffleElements(data.generatedText.choices.map(text => this.addLinkToText(text)))
        }
      } catch (err) {
        const limit = err?.message?.match(/Maximum number of generations of (\d+) reached/)?.[1]
        if (limit) {
          showSnackbarMessage('warning', this.$t('alerts.content-creator.generate-text.limit-reached', { limit }))
        } else {
          showSnackbarMessage('error', this.$t('alerts.content-creator.generate-text.error'))
        }
      }
    },

    async getImages () {
      const noImages = !partMapper[this.kind].includes('image') || !this.topicId || !this.kind || !this.imageSource
      this.generatedContent.image = {}
      if (noImages) {
        return
      }
      const images = await {
        MATERIAL: this.getMaterials,
        STOCK: this.getStockPhotos,
        USER: this.getUserImages,
        AI: this.getAiImages
      }[this.imageSource]()
      this.generatedContent.image = images
      this.setDefaultSelectedContent()
    },

    async getMaterials () {
      if (!this.topic?.landingpage?.id) {
        return { }
      }
      let materials = []
      if (this.getFeature(this.featureNames.LANDINGPAGE).config.hasStaticMaterials) {
        materials = lightMaterials[this.topic.landingpage.slug] || []
      } else if (this.getFeature(this.featureNames.LANDINGPAGE).config.hasMaterials) {
        const { data: { materialsForContentCreator } } = await this.$apollo.query({
          query: GET_MATERIALS,
          variables: {
            input: {
              landingpageId: this.topic.landingpage.id
            }
          }
        })
        materials = materialsForContentCreator
      }
      const materialsWithFiles = materials.reduce((acc, item) => {
        if (item?.files?.length > 0) {
          item.files.forEach(file => acc.push(file?.previewFile || file?.file))
        }
        return acc
      }, []).map(({ cdnUrl, id }) => ({
        preview: setQueryParameters(cdnUrl, { height: 500 }),
        url: cdnUrl,
        id
      }))

      if (materialsWithFiles <= 0) {
        showSnackbarMessage('info', this.$t('alerts.content-creator.material.info'))
        return { }
      }
      return { choices: this.randomlyShuffleElements(materialsWithFiles) }
    },

    async getAiImages () {
      const { data: { generatedImages } } = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: GET_GENERATED_IMAGES,
        variables: {
          input: {
            topicId: this.topic.id
          }
        }
      })
      return {
        choices: [
          ...generatedImages.images.map(({ url, id }) => ({
            preview: setQueryParameters(url, { height: 500 }),
            url,
            id
          })),
          {
            id: 'fetchMore',
            text: generatedImages.total === 0 && this.$t('content-creator.creator.generate-first-image'),
            button: this.$t('buttons.content-creator.generate-image'),
            fetchMore: () => this.generateImage()
          }
        ]
      }
    },

    async getUserImages () {
      const { data: { userImages } } = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: GET_USER_IMAGES,
        variables: {
          input: {
            topicId: this.topic.id
          }
        }
      })
      return {
        choices: [
          ...userImages.images.map(({ url, id }) => ({
            preview: setQueryParameters(url, { height: 500 }),
            url,
            id
          })),
          {
            id: 'uploadImage',
            fetchMore: (file) => this.uploadImage(file)
          }
        ]
      }
    },
    async uploadImage (file) {
      try {
        await this.$apollo.mutate({
          mutation: UPLOAD_USER_IMAGE,
          variables: {
            input: {
              topicId: this.topic.id,
              image: file
            }
          }
        })
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.upload-image.error'))
      }
      this.generatedContent.image = await this.getUserImages()
      this.setDefaultSelectedContent()
    },
    async generateImage () {
      try {
        await this.$apollo.mutate({
          mutation: GENERATE_IMAGE,
          variables: {
            input: {
              topicId: this.topic.id
            }
          },
          refetchQueries: ['getGeneratedImagesThisMonth']
        })
      } catch (err) {
        const limitReached = err?.graphQLErrors?.[0]?.extensions?.exception?.code === 'AI_IMAGE_LIMIT_REACHED'
        if (limitReached) {
          showSnackbarMessage('warning', this.$t('alerts.content-creator.generate-ai-image.limit-reached',
            { limit: err?.graphQLErrors?.[0]?.extensions?.exception?.limit })
          )
        } else {
          showSnackbarMessage('error', this.$t('alerts.content-creator.generate-ai-image.error'))
        }
      }
      this.generatedContent.image = await this.getAiImages()
      this.setDefaultSelectedContent()
    },

    async getStockPhotos () {
      this.stockPhotos.page = 1
      const images = await this.fetchStockPhotos()
      return {
        choices: [
          ...images,
          {
            id: 'fetchMore',
            button: this.$t('buttons.content-creator.load-more'),
            fetchMore: () => this.getMoreStockPhotos()
          }
        ]
      }
    },
    async getMoreStockPhotos () {
      if (this.stockPhotos.page * this.stockPhotos.perPage >= this.stockPhotos.limit) {
        showSnackbarMessage('info', this.$t('alerts.content-creator.generate-stock-image.limit-reached', { limit: this.stockPhotos.limit }))
        return { }
      }
      this.stockPhotos.page++
      const images = await this.fetchStockPhotos()
      this.generatedContent.image = {
        choices: [
          ...images,
          ...this.generatedContent.image.choices
        ]
      }
    },
    async fetchStockPhotos () {
      const { data: { stockPhotos } } = await this.$apollo.query({
        query: GET_STOCK_PHOTOS,
        variables: {
          input: {
            topic: this.topic.id,
            page: this.stockPhotos.page,
            per_page: this.stockPhotos.perPage
          }
        }
      })

      if (stockPhotos.photos.length === 0) {
        showSnackbarMessage('info', this.$t('alerts.content-creator.generate-stock-image.info'))
        return []
      }
      return stockPhotos.photos.map(photo => ({
        preview: setQueryParameters(photo.src.original, { h: 500, w: 500, auto: 'compress', fit: 'crop' }),
        url: setQueryParameters(photo.src.original, { h: 1500, w: 1500, auto: 'compress', fit: 'crop' }),
        id: photo.id
      }))
    },
    openSocialMediaDialog () {
      this.showSocialMediaDialog = true
    }
  },
  apollo: {
    topics: {
      query: GET_TOPICS
    },
    socialMediaConnections: {
      query: GET_SOCIAL_MEDIA_CONNECTIONS
    },
    latestGeneratedText: {
      query: GET_LATEST_GENERATED_TEXT,
      variables () {
        return {
          input: {
            templateId: this.topic?.textAiTopicTemplate?.id
          }
        }
      },
      skip () {
        return !this.topic?.textAiTopicTemplate?.id
      },
      update (data) {
        if (data?.latestGeneratedText?.choices?.length > 0) {
          this.generatedContent.text = {
            choices: this.randomlyShuffleElements(data.latestGeneratedText.choices.map(text => this.addLinkToText(text)))
          }
          this.setDefaultSelectedContent()
        } else {
          this.generate()
        }
      }
    }
  }
}
</script>

<style scoped>
  @media (max-width: 960px) {
    .generate-btn {
      margin-left: 0;
      margin-right: 0;
    }
    .generate-btn, .social-media-btn {
      width: 100%;
    }
  }

  .pointer {
    cursor: pointer;
  }

  ::v-deep fieldset   {
    min-width: 64px;
    padding: 0 16px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
  }

</style>
