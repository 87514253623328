<template>
  <v-dialog
    v-model="connectDialog"
    width="600"
    transition="dialog-bottom-transition"
    persistent
  >
    <template v-if="isConnectingFinished || isConnectingFailed">
      <ConnectSocialMediaPage
        v-if="connectSocialMediaPage"
        :social-media-pages="socialMediaPages"
        :content="content"
        @back="openConnectSocialMediaDialog"
        @close="closeDialog"
        @savedContent="$emit('savedContent')"
        @reconnectToSocialMedia="reconnectToSocialMedia"
      />
      <ConnectSocialMedia
        v-if="connectSocialMediaDialog"
        :social-media-pages="socialMediaPages"
        :content-kind="content.textKind"
        :has-instagram-connection="hasInstagramConnection"
        @openConnectSocialMediaPage="openConnectSocialMediaPage"
        @close="closeDialog"
        @connectToSocialMedia="generateConnection"
      />
    </template>
    <v-card
      v-if="isConnecting"
      class="mx-auto"
      max-width="600"
    >
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <div class="d-flex justify-space-between toolbar">
            <v-toolbar-title class="my-auto">
              {{ $t('social-media.info-dialog') }}
            </v-toolbar-title>
          </div>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <ProgressCircular />
        <v-card-title class="d-flex justify-center">
          <span class="text-center">{{ $t('alerts.social-media.connecting-to-social-media.info') }}</span>
        </v-card-title>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ConnectSocialMediaPage from '@/modules/contentCreator/creator/SocialMedia/ConnectSocialMediaPage.vue'
import ConnectSocialMedia from '@/modules/contentCreator/creator/SocialMedia/ConnectSocialMedia.vue'
import ProgressCircular from '@/components/ProgressCircular.vue'
import GET_FACEBOOK_PAGES from '@/modules/contentCreator/creator/queries/getFacebookPages.gql'
import KindType, { KindName } from '@/modules/contentCreator/creator/enums/KindType'
import CONNECT_TO_SOCIAL_MEDIA from '@/modules/contentCreator/creator/queries/connectSocialMedia.gql'
import {
  facebookSDKInitialized,
  getAccessToken,
  initFbSDK,
  loginToFacebook,
  loginToInstagram,
  logoutFromFacebook
} from '@/lib/facebook'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import { SocialMedia } from '@/modules/contentCreator/creator/enums/SocialMedia'
const FB_APP_ID = process.env.VUE_APP_FB_APP_ID

export default {
  components: { ProgressCircular, ConnectSocialMediaPage, ConnectSocialMedia },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    socialMediaConnections: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      state: 'connected',
      socialMediaPages: null,
      connectSocialMediaDialog: false,
      connectSocialMediaPage: false,
      connectDialog: true
    }
  },
  computed: {
    isConnecting () {
      return this.state === 'isConnecting'
    },
    isConnectingFinished () {
      return this.state === 'connected'
    },
    isConnectingFailed () {
      return this.state === 'error'
    },
    hasFacebookConnection () {
      return this.socialMediaConnections &&
        this.socialMediaConnections?.facebook
    },
    hasInstagramConnection () {
      return this.socialMediaConnections &&
        this.socialMediaConnections?.instagram
    },
    isContentKindFacebookPost () {
      return this.content.textKind === KindType.facebookPost
    },
    isContentKindInstagramPost () {
      return this.content.textKind === KindType.instagramPost
    }
  },
  async mounted () {
    if (this.hasFacebookConnection) {
      await this.getFacebookPages()
    }

    const isFacebookPost = this.content.textKind === KindType.facebookPost
    const isInstagramPost = this.content.textKind === KindType.instagramPost
    if ((isFacebookPost && this.hasFacebookConnection) ||
      (isInstagramPost && this.hasInstagramConnection)) {
      this.openConnectSocialMediaPage()
    } else {
      this.openConnectSocialMediaDialog()
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
      this.connectDialog = false
    },
    openConnectSocialMediaDialog () {
      this.connectSocialMediaDialog = true
      this.connectSocialMediaPage = false
    },
    openConnectSocialMediaPage () {
      this.connectSocialMediaDialog = false
      this.connectSocialMediaPage = true
    },

    async reconnectToSocialMedia (kind) {
      if (kind.name === KindName.facebookPost) {
        if (!facebookSDKInitialized()) {
          await initFbSDK(FB_APP_ID)
        }

        if (getAccessToken()) {
          await logoutFromFacebook()
        }

        await this.generateConnection(KindName.facebookPost)
      }
    },

    async generateConnection (kind) {
      if (kind === KindName.facebookPost || (kind === KindName.instagramPost && !this.hasFacebookConnection)) {
        await this.generateFacebookConnection()
      }

      if (kind === KindName.instagramPost) {
        await this.generateInstagramConnection()
      }

      if (this.isConnectingFailed) {
        this.openConnectSocialMediaDialog()
      } else {
        this.openConnectSocialMediaPage()
      }

      this.$emit('refreshSocialMediaConnections')
      await this.getFacebookPages()
    },

    async generateFacebookConnection () {
      if (!facebookSDKInitialized()) {
        await initFbSDK(FB_APP_ID)
      }
      try {
        this.state = 'isConnecting'
        const login = await loginToFacebook()
        await this.connectToSocialMedia(login.authResponse.accessToken, login.authResponse.userID, SocialMedia.FACEBOOK)
        showSnackbarMessage('success', this.$t('alerts.social-media.connect-to-facebook.success'))
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-facebook.error'))
        this.state = 'error'
      }
    },

    async generateInstagramConnection () {
      if (!facebookSDKInitialized()) {
        await initFbSDK(FB_APP_ID)
      }

      this.state = 'isConnecting'
      try {
        await loginToInstagram()
        await this.connectToSocialMedia(null, null, SocialMedia.INSTAGRAM)
        this.state = 'connected'
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-instagram.error'))
        this.state = 'error'
      }
    },

    async connectToSocialMedia (accessToken, userId, socialMedia) {
      try {
        await this.$apollo.mutate({
          mutation: CONNECT_TO_SOCIAL_MEDIA,
          variables: {
            input: {
              type: socialMedia,
              accessToken: accessToken,
              userId: userId
            }
          }
        })
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.social-media.connecting-to-social-media.error'))
      }
    },

    async getFacebookPages () {
      this.state = 'isConnecting'
      try {
        const { data } = await this.$apollo.query({
          query: GET_FACEBOOK_PAGES,
          fetchPolicy: 'no-cache'
        })

        if (!data?.facebookPages || data.facebookPages.length === 0) {
          this.socialMediaPages = null
          this.state = 'connected'
          return
        }

        this.socialMediaPages = {
          facebook: this.formatFacebookPages(data.facebookPages)
        }

        if (this.hasInstagramConnection) {
          this.socialMediaPages.instagram = this.formatInstagramPages(data.facebookPages)
        }

        this.state = 'connected'
      } catch (err) {
        this.handleError()
      }
    },
    formatFacebookPages (pages) {
      return pages.map(page => ({
        ...page,
        selected: this.isContentKindFacebookPost ? page.isActive : false,
        isActive: page.isActive,
        type: KindType.facebookPost
      })).sort((a, b) => b.isActive - a.isActive)
    },

    formatInstagramPages (pages) {
      const instagramPages = pages.filter(page => page.instagram_business_account)
      return instagramPages.map(page => {
        const instagramAccount = page.instagram_business_account
        return {
          facebookId: page.id,
          id: instagramAccount.id,
          name: instagramAccount.username,
          selected: this.isContentKindInstagramPost ? page.isActive : false,
          isActive: page.isActive,
          type: KindType.instagramPost
        }
      }).sort((a, b) => b.isActive - a.isActive)
    },

    handleError () {
      this.state = 'connected'
      this.openConnectSocialMediaPage()
      showSnackbarMessage('error', this.$t('alerts.social-media.something-went-wrong'))
    }

  }

}
</script>
