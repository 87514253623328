<template>
  <div class="pa-md-6 creator-text-box w-full">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
          class="edit"
          v-on="on"
          @click="handleClick"
        >
          mdi-pencil-outline
        </v-icon>
      </template>
      <span>{{ $t('buttons.content-creator.edit') }}</span>
    </v-tooltip>

    <p
      ref="measuringDiv"
      :style="{ 'width': currentWidth - 70 + 'px' }"
      class="creator-text-box-content pointer"
      @click="handleClick"
      v-text="content"
    />
  </div>
</template>

<script>

export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    currentWidth: {
      type: Number,
      default: 250
    },
    recalculateHeight: {
      type: Number,
      default: null
    }
  },
  watch: {
    recalculateHeight () {
      this.calculateHeight()
    }
  },
  mounted () {
    this.calculateHeight()
  },
  methods: {
    calculateHeight () {
      this.$emit('currentHeight', this.$refs.measuringDiv.offsetHeight)
    },
    handleClick () {
      this.$emit('handleClick')
    }
  }
}
</script>
<style scoped>
.creator-text-box {
  overflow: auto;
  scrollbar-width: thin;
  height: 100%;

}

@media (max-width: 960px) {
  .creator-text-box {
    padding: 10px 20px;
  }
  .edit {
    display: none;
  }

  .creator-text-box-content {
    font-size: 12px;
    padding: 10px;
  }
}

.creator-text-box-content {
  white-space: pre-wrap;
  font-size: 14px;
}

.edit {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.pointer {
  cursor: pointer;
}
</style>
