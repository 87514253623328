<template>
  <v-col>
    <div class="w-full d-flex justify-center">
      <div class="loading-box d-flex align-center flex-column pa-4">
        <v-progress-circular
          class="mr-2"
          width="5"
          size="100"
          color="primary"
          indeterminate
        />
      </div>
    </div>
  </v-col>
</template>
<script>
export default {}
</script>
