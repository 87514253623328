<template>
  <v-btn
    :text="!primary"
    :outlined="!primary"
    :disabled="disabled || isLoading"
    :loading="isLoading"
    class="download-btn pointer"
    :color="primary ? 'primary' : ''"
    elevation="0"
    @click="downloadPost"
  >
    <v-icon
      class="mr-sm-2 pt-1"
      size="14"
    >
      mdi-download
    </v-icon>

    {{ $t('buttons.content-creator.download') }}
  </v-btn>
</template>

<script>
import UPDATE_SAVED_CONTENT from '@/modules/contentCreator/creator/queries/updateSavedContent.gql'
import SAVE_CONTENT from './queries/saveContent.gql'

import { ReadableKindType } from './enums/KindType'
import { createAndDownloadZip } from '@/lib/generateZip'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import trackingEvents from '@/lib/trackingEvents'
import { buildUrlWithTracking } from '@/modules/contentCreator/creator/lib/urlWithTracking'

export default {
  mixins: [featureMixin, brandingMixin],
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    savedContentId: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    imageSource: {
      type: String,
      default: ''
    },
    kind: {
      type: String,
      default: ''
    },
    topic: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    topicId: {
      type: String,
      default: ''
    },
    topicTemplateId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async downloadPost () {
      this.isLoading = true
      await this.downloadZipFile()
      if (this.savedContentId) {
        await this.updateSavedContent()
      } else {
        await this.storeSavedContent(null)
      }
      this.$emit('savedContent')
      this.isLoading = false
    },
    async storeSavedContent (postType) {
      try {
        await this.$apollo.mutate({
          mutation: SAVE_CONTENT,
          variables: {
            input: {
              text: this.text ?? null,
              imagePath: this.image ?? null,
              imageSource: this.imageSource,
              textKind: this.kind,
              topicId: this.topicId,
              topicTemplateId: this.topicTemplateId,
              postedOn: postType ? [postType] : [],
              slug: this.topic,
              url: buildUrlWithTracking(this.url, this.kind, this.readablePlatformName),
              downloaded: postType === null
            }
          }
        })
        this.$gtm.trackEvent({
          event: postType ? trackingEvents.CONTENT_CREATOR_SHARED : trackingEvents.CONTENT_CREATOR_DOWNLOADED,
          value: {
            type: ReadableKindType[this.kind],
            topic: this.topic
          }
        })

        showSnackbarMessage('info', this.$t('alerts.content-creator.save-content.success'))
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.save-content.error'))
      }
    },
    async updateSavedContent () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_SAVED_CONTENT,
          variables: {
            input: {
              id: this.savedContentId,
              downloaded: true
            }
          }
        })
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.save-content.error'))
      }
    },
    async downloadZipFile () {
      if (!this.text && !this.image) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.general.error'))
        return
      }

      const zipFileCreated = await createAndDownloadZip(
        {
          text: this.text ?? null,
          imagePath: this.image ?? null,
          textKind: ReadableKindType[this.kind],
          topic: this.topic,
          url: buildUrlWithTracking(this.url, this.kind, this.readablePlatformName)
        })

      if (!zipFileCreated) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.create-zip-file.error'))
      }
    }
  }
}
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }

  .download-btn:after {
    text-decoration: underline;
  }

  ::v-deep fieldset   {
    min-width: 64px;
    padding: 0 16px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
  }

</style>
