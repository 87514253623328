<template>
  <div class="ma-4">
    <span class="font-weight-bold">
      {{ $t('content-creator.limits.headline') }}
    </span>
    <ul>
      <li>{{ $t('content-creator.limits.generated-texts') }} <strong>{{ generatedTextsThisMonth }}</strong> {{ $t('content-creator.limits.of') }} <strong>{{ aiTextLimit }}</strong></li>
      <li>{{ $t('content-creator.limits.generated-images') }} <strong>{{ generatedImagesThisMonth }}</strong> {{ $t('content-creator.limits.of') }} <strong>{{ aiImageLimit }}</strong></li>
    </ul>
  </div>
</template>
<script>
import GET_GENERATED_IMAGES_THIS_MONTH from './queries/getGeneratedImagesThisMonth.gql'
import GET_GENERATED_TEXTS_THIS_MONTH from './queries/getGeneratedTextsThisMonth.gql'
import featureMixin from '@/mixins/feature'

export default {
  mixins: [featureMixin],
  data () {
    return {
      aiTextLimit: 0,
      aiImageLimit: 0
    }
  },
  async mounted () {
    const feature = await this.getFeature(this.featureNames.CONTENT_CREATOR)
    const textAifeature = await this.getFeature(this.featureNames.TEXT_AI_TOPICS)
    this.aiTextLimit = textAifeature.config.maxGenerationsPerMonth === -1 ? this.$t('content-creator.limits.unlimited') : textAifeature.config.maxGenerationsPerMonth
    this.aiImageLimit = feature.config.aiImageLimit === -1 ? this.$t('content-creator.limits.unlimited') : feature.config.aiImageLimit
  },
  apollo: {
    generatedImagesThisMonth: {
      query: GET_GENERATED_IMAGES_THIS_MONTH
    },
    generatedTextsThisMonth: {
      query: GET_GENERATED_TEXTS_THIS_MONTH
    }
  }
}
</script>
