<template>
  <div
    v-if="content.fetchMore"
    class="w-full h-full d-flex flex-column justify-center px-6 align-center"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-center align-center"
    >
      <ProgressCircular />
    </div>
    <div
      v-else-if="content.id === 'uploadImage'"
      class="d-flex flex-column justify-center align-center"
    >
      <CropImageUpload
        show-drop-zone
        :min-height="1000"
        :min-width="1000"
        accept="image/png,image/jpg"
        :ratio="1"
        :auto-crop-area="1"
        :save="fetch"
        ask-for-image-rights
      />
    </div>
    <div
      v-else
      class="d-flex flex-column justify-center align-center"
    >
      <div class="pa-6">
        {{ content.text || $t('content-creator.creator.no-suitable-image') }}
      </div>
      <v-btn
        color="primary"
        dark
        @click="fetch"
      >
        {{ content.button }}
      </v-btn>
    </div>
  </div>
  <v-img
    v-else
    :src="content.preview"
    :style="{ 'display: none': isLoading }"
    contain
    class="w-full h-full img"
    @click="handleClick"
  >
    <template v-slot:error>
      <v-img
        :src="require('@/../public/img/image-not-found-icon.png')"
        contain
        class="w-full h-full"
      />
    </template>
  </v-img>
</template>
<script>
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import ProgressCircular from '@/components/ProgressCircular.vue'
import CropImageUpload from '@/components/CropImageUpload.vue'

export default {
  components: { ProgressCircular, CropImageUpload },
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      uploadedImage: null,
      isLoading: false
    }
  },
  methods: {
    async fetch (...args) {
      this.isLoading = true
      try {
        await this.content.fetchMore(...args)
      } catch (error) {
        showSnackbarMessage('error', this.$t('content-creator.creator.error-fetching-image'))
      } finally {
        this.isLoading = false
      }
    },
    handleClick () {
      this.$emit('handleClick')
    }
  }
}
</script>
