<template>
  <v-alert
    class="my-4 mx-sm-4 w-xs-full"
    icon="mdi-information-slab-circle"
    text
    type="info"
  >
    {{ infoMessage }}
  </v-alert>
</template>

<script>
export default {
  props: {
    infoMessage: {
      type: String,
      default: null
    }
  }
}
</script>
