<template>
  <div>
    <Connect
      v-if="showSocialMediaDialog"
      :social-media-connections="socialMediaConnections"
      :content="selectedContentForPosting"
      :post-from-history-page="true"
      @updateContent="updatePostedOn"
      @close="closePostToFacebookDialog"
    />
    <v-tab-item :value="filterValue">
      <BlurredBackdrop :is-blurred="disabled">
        <template v-slot:info>
          <div>
            {{ $t('alerts.upselling.upgrade') }}
          </div>
        </template>
        <v-timeline
          :dense="$vuetify.breakpoint.smAndDown"
        >
          <v-timeline-item
            v-for="content in savedContents"
            :key="content.id"
            class="v-timeline-item"
          >
            <template v-slot:icon>
              <v-avatar>
                <v-img
                  :src="content.icon"
                  class="timeline-avatar"
                  contain
                />
              </v-avatar>
            </template>
            <span slot="opposite">

              <span class="createdAt">
                {{ $t('content-creator.created-on') }}:</span>
              <p class="createdAt-content">{{ content.createdAt }}</p>
            </span>
            <v-card class="elevation-2">
              <div class="d-md-flex flex-md-no-wrap justify-md-space-between">
                <div>
                  <v-card-title class="text-h5 px-2 px-sm-4 pb-0 pb-sm-4">
                    {{ content.textKind }}
                  </v-card-title>
                  <v-tooltip
                    content-class="tooltip-class"
                    max-width="500"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-card-text class="pa-2 pa-sm-4">
                          {{ textTruncate(content.text, 150) }}
                        </v-card-text>
                      </span>
                    </template>

                    <span>
                      <v-alert
                        class="mt-5 alert"
                        icon="mdi-information-slab-circle"
                        type="info"
                        color="primary"
                      >
                        {{ content.text }}
                      </v-alert>
                    </span>
                  </v-tooltip>
                </div>
                <v-img
                  v-if="content.image"
                  class="ma-2 ma-sm-4 w-full"
                  :src="content.image"
                  max-width="175"
                />
              </div>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-text class="pt-0 px-2 px-sm-4 d-md-flex">
                  <div class="mr-md-2 d-md-flex align-center">
                    <span
                      v-if="content.postedOn?.length > 0"
                      class="mr-1"
                    >
                      {{ $t('content-creator.table.labels.posted-on') }}:
                    </span>
                    <v-tooltip
                      v-for="(postedOn, index) in content.postedOn"
                      :key="index"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-avatar
                          :size="$vuetify.breakpoint.smAndDown ? 20 : 25"
                          class="mr-1 mr-md-2 mb-1 mb-sm-0"
                          tile
                          v-on="on"
                        >
                          <v-img :src="KindTypeIcon[postedOn]" />
                        </v-avatar>
                      </template>
                      <span class="mr-1 mr-sm-2">{{ KindName[postedOn] }}</span>
                    </v-tooltip>
                  </div>
                  <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

                  <span :class="{ 'col-12 pa-0': $vuetify.breakpoint.smAndDown }">
                    <span
                      class="mr-sm-2 py-1"
                      :class="{ 'col-12 px-0': $vuetify.breakpoint.smAndDown }"
                    >
                      {{ $t('content-creator.table.labels.downloaded') }}:
                      <v-icon
                        v-if="content.downloaded"
                        size="25"
                        color="primary"
                      >
                        mdi-check-circle
                      </v-icon>

                      <v-avatar
                        v-if="!content.downloaded"
                        size="25"
                        tile
                      >
                        <v-img
                          :src="require('@/../public/icons/close.png')"
                          class="pointer"
                        />
                      </v-avatar>
                    </span>
                    <span class="mr-sm-2">
                      <v-btn
                        :class="{ 'col-12 mb-2': $vuetify.breakpoint.smAndDown }"
                        outlined
                        :disabled="disabled"
                        elevation="0"
                        color="primary"
                        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : ''}"
                        @click="setupPostToFacebookDialog(content)"
                      >
                        <v-icon
                          class="mr-2"
                          size="14"
                        >
                          mdi-share-variant
                        </v-icon>
                        {{ $t('buttons.content-creator.history.share') }}
                      </v-btn>
                    </span>
                    <span class="mr-2">
                      <DownloadPost
                        :class="{ 'col-12': $vuetify.breakpoint.smAndDown }"
                        :saved-content-id="content.id"
                        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : ''}"
                        :text="content.text"
                        :image="content.image"
                        :image-source="content.imageSource"
                        :kind="content.kind"
                        :topic="content.slug"
                        :topic-id="content.topicId"
                        :url="content.url"
                        :topic-template-id="content.topicTemplateId"
                        :disabled="disabled"
                        @savedContent="$emit('savedContent')"
                      />
                    </span>
                  </span>
                </v-card-text>
              </div>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </BlurredBackdrop>
    </v-tab-item>
  </div>
</template>

<script>
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import { textTruncate } from '@/lib/truncate'
import { PostType } from '@/modules/contentCreator/creator/enums/PostType'
import UPDATE_SAVED_CONTENT from '@/modules/contentCreator/creator/queries/updateSavedContent.gql'
import { KindName, KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import BlurredBackdrop from '@/components/BlurredBackdrop.vue'
import Connect from '@/modules/contentCreator/creator/SocialMedia/Connect.vue'
import GET_SOCIAL_MEDIA_CONNECTIONS from '@/modules/contentCreator/creator/queries/getSocialMediaConnections.gql'
import DownloadPost from './DownloadPost.vue'

export default {
  components: { Connect, DownloadPost, BlurredBackdrop },
  props: {
    savedContents: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      KindName,
      KindTypeIcon,
      PostType,
      socialMediaConnections: null,
      showSocialMediaDialog: false,
      selectedContentForPosting: {}
    }
  },
  methods: {
    textTruncate,
    async updateSavedContent ({ id, downloaded, postedOn }) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_SAVED_CONTENT,
          variables: {
            input: {
              id,
              downloaded,
              postedOn
            }
          }
        })

        if (data?.updateSavedContent) {
          const { downloaded, postedOn } = data.updateSavedContent
          const content = this.savedContents.find(item => item.id === id)
          content.downloaded = downloaded
          content.postedOn = postedOn
        }
      } catch (err) {
        showSnackbarMessage('error', this.$t('alerts.content-creator.save-content.error'))
      }
    },

    async updatePostedOn (PostType) {
      if (this.selectedContentForPosting.postedOn.some(item => item === PostType)) return

      await this.updateSavedContent({
        id: this.selectedContentForPosting.id,
        postedOn: [...this.selectedContentForPosting.postedOn, PostType],
        downloaded: this.selectedContentForPosting.downloaded
      })

      this.closePostToFacebookDialog()
    },

    async setupPostToFacebookDialog (content) {
      this.selectedContentForPosting = {
        id: content.id,
        image: content.image,
        text: content.text,
        postedOn: content.postedOn,
        textKind: content.kind,
        downloaded: content.downloaded
      }
      this.openPostToFacebookDialog()
    },

    openPostToFacebookDialog () {
      this.showSocialMediaDialog = true
    },

    closePostToFacebookDialog () {
      this.showSocialMediaDialog = false
    }
  },
  apollo: {
    socialMediaConnections: {
      query: GET_SOCIAL_MEDIA_CONNECTIONS
    }
  }
}
</script>

<style scoped>
.v-timeline-item {
  margin-left: 200px;
  margin-right: 200px;
}

@media (min-width: 962px) and (max-width: 1740px) {
  .v-timeline-item {
    margin-left: 60px;
    margin-right: 60px;
  }
}
@media (min-width: 0) and (max-width: 1540px) {
  .v-timeline-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.timeline-avatar {
  background-color: white;
}

.createdAt {
  color: #99A1B7;
  font-weight: 600;
}

.createdAt-content {
  color: #071437;
  font-weight: 500;
}

.table-row {
  border-bottom: 1px solid #e0e0e0;
}

.pointer {
  cursor: pointer;
}

.tooltip-class {
  background-color: transparent;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}

.th-downloaded {
  width: 50px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  padding: 0 8px;
  font-size: 10px;
}
</style>
